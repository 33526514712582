@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Fauna+One&display=swap');

@import url('boska.css');

@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Fauna+One&family=Gluten:wght@400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.app {
  margin: auto;
}

.markdown-component {
  font-size: large;
}

.recipe-prep-markdown-component {
  font-size: 25px;
  text-align: justify;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}